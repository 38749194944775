const mail = document.body.querySelector('#about #mail');

const email = document.createElement('a');
const decoded = atob('bWFpbHRvOkN4UmVTQHBSb1RvTi5tRQ==');
email.textContent = decoded.substring(7);
email.href = decoded.toLowerCase().replace('@', '+website@');
email.title = "Send E-mail";
email.rel = "foaf:mbox";

mail.prepend(email);
